





















import { Component, Watch } from 'vue-property-decorator'
import Color from '@/constants/color'
import Slide from '@/mixins/Slide.ts'
import { mixins } from 'vue-class-component'
import MediaContent from '@/components/MediaContent.vue'
import ScrollerElement, { ScrollerElementAnimationEvent } from '@/components/ScrollerElement.vue'
import ArrowDownNextSlide from '@/components/ArrowDownNextSlide.vue'
import CheatSheet from '@/components/CheatSheet.vue'

@Component({
    components: {
        ScrollerElement,
        MediaContent,
        ArrowDownNextSlide,
        CheatSheet
    }
})
export default class SlideIntro extends mixins(Slide) {
    $refs!: {
        backgroundMedia: MediaContent
        logo: MediaContent
    }

    get walkerItem() {
        return this.walker.item as SlideWalkerItemIntro
    }

    get backgroundMedia(): RoadizDocument | undefined {
        return this.walkerItem.backgroundMedia?.[0]
    }

    populateAppear(timeline: GSAPTimeline) {
        if (this.$refs.backgroundMedia) {
            timeline.from(
                this.$refs.backgroundMedia.$el,
                {
                    opacity: 0.8,
                    scale: 1.2,
                    duration: 2.4,
                    ease: 'power2'
                },
                0.5
            )
        }
    }

    populateEnterY({ timeline }: ScrollerElementAnimationEvent) {
        if (this.$refs.backgroundMedia) {
            timeline.fromTo(
                this.$refs.backgroundMedia.$el,
                {
                    opacity: 0.8,
                    scale: 1.2
                },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 1,
                    ease: 'none'
                },
                0
            )
        }

        if (this.$refs.logo) {
            timeline.fromTo(
                this.$refs.logo.$el,
                {
                    opacity: 0
                },
                {
                    opacity: 1,
                    duration: 0.5,
                    ease: 'none'
                },
                0.4
            )
        }
    }

    @Watch('walker', { immediate: true })
    onWalkerChange() {
        // Default is DARK on intro
        this.color = (this.walker.item as SlideWalkerItemIntro).invertContrast ? Color.LIGHT : Color.DARK
    }
}
